import {
	json,
	type MetaFunction,
	type LoaderFunctionArgs,
} from '@remix-run/node'
import { Link, useLoaderData } from '@remix-run/react'
import { Image } from 'remix-image'
import config from '#app/config.ts'
import { prisma } from '#app/utils/db.server.ts'
import { stageName } from '#app/utils/format.ts'
import { getNoteImgSrc, invariantResponse } from '#app/utils/misc.tsx'
import renderMarkdown from '#app/utils/render-markdown.tsx'

const take = 25

export const meta: MetaFunction = () => [{ title: config.title }]

export async function loader({ request }: LoaderFunctionArgs) {
	const url = new URL(request.url)
	const skip = Number(url.searchParams.get('skip') ?? 0)

	const latestNote = await prisma.note.findFirst({
		select: {
			id: true,
			title: true,
			stage: true,
			content: true,
			createdAt: true,
			images: { select: { id: true, altText: true } },
			topics: { select: { id: true, name: true } },
			owner: { select: { id: true, name: true, username: true } },
		},
		orderBy: { createdAt: 'desc' },
		where: { access: 'public' },
		take: 1,
	})
	const [recentlyUpdated, links, topics, topicsCount] = await Promise.all([
		prisma.note.findMany({
			select: {
				id: true,
				title: true,
				stage: true,
				updatedAt: true,
				owner: { select: { id: true, name: true, username: true } },
			},
			where: { NOT: { id: latestNote?.id }, access: 'public' },
			orderBy: { updatedAt: 'desc' },
			take: 5,
		}),
		prisma.link.findMany({
			select: {
				id: true,
				title: true,
				url: true,
				createdAt: true,
			},
			orderBy: { createdAt: 'asc' },
			take: 5,
		}),
		prisma.topic.findMany({
			orderBy: {
				createdAt: 'desc',
			},
			take: 5,
		}),
		prisma.topic.count(),
	])
	const notes = await prisma.note.findMany({
		select: {
			id: true,
			title: true,
			summary: true,
			stage: true,
			createdAt: true,
			topics: { select: { id: true, name: true } },
			owner: { select: { id: true, name: true, username: true } },
		},
		orderBy: { createdAt: 'desc' },
		where: { access: 'public' },
		skip,
		take,
	})

	invariantResponse(notes, 'Notes not found', { status: 404 })

	return json({
		notes: notes.slice(1),
		latestNote,
		recentlyUpdated,
		links,
		topics,
		topicsCount,
		take,
		skip,
	})
}

export default function Index() {
	const data = useLoaderData<typeof loader>()
	// +1 includes the top note
	const hideNextAndPrevious =
		data.skip === 0 && data.notes.length + 1 < data.take

	return (
		<main className="relative flex min-h-screen flex-col items-center gap-8">
			<div className="w-full p-8 bg-muted">
				<section className="flex flex-col gap-3">
					{data.latestNote && (
						<>
							<Link
								to={`/notes/${data.latestNote.id}`}
								className="hover:underline"
							>
								<h3 className="text-2xl font-bold leading-tight">
									{data.latestNote.title}
								</h3>
							</Link>

							<p className="text-sm leading-normal">
								By{' '}
								<Link
									to={`/users/${data.latestNote.owner.username}`}
									className="hover:underline"
								>
									{data.latestNote.owner.name ?? data.latestNote.owner.username}
								</Link>{' '}
								on {new Date(data.latestNote.createdAt).toLocaleDateString()},{' '}
								{stageName(data.latestNote.stage)}
								{data.latestNote.topics.length > 0 && (
									<>
										{' '}
										-{' '}
										{data.latestNote.topics.map((topic, index) => (
											<span key={topic.id}>
												<Link
													className="hover:underline"
													to={`/topics/${topic.id}`}
												>
													{topic.name}
												</Link>
												{data.latestNote &&
												index < data.latestNote.topics.length - 1
													? ', '
													: ''}
											</span>
										))}
									</>
								)}
							</p>

							<div className="prose prose-sm xl:prose-lg prose-stone dark:prose-invert">
								{renderMarkdown(data.latestNote.content)}
							</div>

							{data.latestNote.images.length > 0 && (
								<ul className="flex flex-wrap gap-5 py-5">
									{data.latestNote.images.map(image => (
										<li key={image.id}>
											<a href={getNoteImgSrc(image.id)}>
												<Image
													src={getNoteImgSrc(image.id, true)}
													alt={image.altText ?? ''}
													className="h-32 w-32 rounded-lg object-cover"
													loaderUrl="/api/image"
													responsive={[
														{
															size: { width: 128, height: 128 },
														},
													]}
													dprVariants={[3]}
												/>
											</a>
										</li>
									))}
								</ul>
							)}
						</>
					)}
				</section>
			</div>

			{data.topics.length > 0 && (
				<section className="w-full">
					<div className="container mx-auto">
						<div className="flex flex-col gap-8">
							<h2 className="text-lg sr-only">Topics</h2>

							<ul className="flex flex-row gap-2 flex-wrap">
								{data.topics.map((topic, index) => (
									<li key={topic.id} className="flex gap-2 items-center">
										<Link
											to={`/topics/${topic.id}`}
											className="hover:underline"
										>
											<h3 className="text-xl font-semiBold leading-tight m-0">
												{topic.name}
											</h3>
										</Link>{' '}
										{index < data.topics.length - 1 && (
											<span className="text-2xl">·</span>
										)}
									</li>
								))}
								{data.topicsCount > data.topics.length && (
									<li className="flex gap-2 items-center">
										<Link to="/topics" title="View all topics" className="ml-2">
											<span className="text-2xl">→</span>
										</Link>
									</li>
								)}
							</ul>
						</div>

						<hr className="border-stone-300 mt-2" />
					</div>
				</section>
			)}

			<section className="w-full">
				<div className="container mx-auto">
					<div className="flex flex-col gap-6 md:flex-row-reverse">
						<div className="flex min-w-[200px] flex-1 flex-col gap-6">
							{data.links.length > 0 && (
								<div className="flex flex-col gap-2">
									<h3 className="underline">Links</h3>

									<ul className="flex flex-col gap-2">
										{data.links.map(link => (
											<li key={link.id}>
												<a
													href={link.url}
													target="__blank"
													className="hover:underline"
												>
													<h3 className="font-bold leading-tight">
														{link.title}
													</h3>
												</a>
											</li>
										))}
									</ul>
								</div>
							)}

							<div className="flex flex-col gap-2">
								<h3 className="underline">Recently updated</h3>

								<ul className="flex flex-col gap-2">
									{data.recentlyUpdated.map(note => (
										<li key={note.id}>
											<Link to={`/notes/${note.id}`} className="group">
												<h3 className="font-bold leading-tight group-hover:underline">
													{note.title}
												</h3>
												<div className="text-sm text-stone-600">
													{new Date(note.updatedAt).toLocaleDateString()},{' '}
													{stageName(note.stage)}
												</div>
											</Link>
										</li>
									))}
								</ul>
							</div>
						</div>

						<div className="flex flex-wrap gap-4 flex-[3]">
							{data.notes.map(note => (
								<div key={note.id} className="w-full">
									<div className="flex flex-col gap-2">
										<div className="flex flex-col gap-1">
											<Link
												to={`/notes/${note.id}`}
												className="hover:underline"
											>
												<h3 className="text-xl font-bold leading-tight">
													{note.title}
												</h3>
											</Link>

											<p className="text-sm leading-normal">
												By{' '}
												<Link
													to={`/users/${note.owner.username}`}
													className="hover:underline"
												>
													{note.owner.name ?? note.owner.username}
												</Link>{' '}
												on {new Date(note.createdAt).toLocaleDateString()},{' '}
												{stageName(note.stage)}
												{note.topics.length > 0 && (
													<>
														{' '}
														-{' '}
														{note.topics.map((topic, index) => (
															<span key={topic.id}>
																<Link
																	className="hover:underline"
																	to={`/topics/${topic.id}`}
																>
																	{topic.name}
																</Link>
																{index < note.topics.length - 1 ? ', ' : ''}
															</span>
														))}
													</>
												)}
											</p>
										</div>

										{note.summary && (
											<p className="text-sm leading-normal">{note.summary}</p>
										)}
									</div>
								</div>
							))}

							{data.notes.length === 0 && (
								<div className="w-full text-stone-600">
									<p>No notes found</p>
								</div>
							)}

							{!hideNextAndPrevious && (
								<div className="flex w-full justify-between px-36 py-10">
									<Link
										aria-disabled={data.skip === 0}
										to={{
											search: `?skip=${Math.max(data.skip - data.take, 0)}`,
										}}
										className="aria-disabled:pointer-events-none aria-disabled:text-stone-500"
									>
										Previous
									</Link>

									<Link
										aria-disabled={data.notes.length + 1 < data.take}
										to={{ search: `?skip=${data.skip + data.take}` }}
										className="aria-disabled:pointer-events-none aria-disabled:text-stone-500"
									>
										Next
									</Link>
								</div>
							)}
						</div>
					</div>
				</div>
			</section>
		</main>
	)
}
